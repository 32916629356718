import styles from './Loading.module.css';

function Loading({msg}) {
    return (
        // <div className={`${styles["container"]} code`}>
        //     <div>Loading... {msg}</div>
        // </div>
        <div className={styles.container}>
            <img
                src="/android-chrome-512x512.png"
                alt="App Logo"
                className={`${styles["logo"]} flip-anim`}
            />
            {/*<div className={styles.waveText}>*/}
            {/*    <div>Opus</div>*/}
            {/*    <div>Boost</div>*/}
            {/*</div>*/}
        </div>

    )
    ;
}

export default Loading;
